.project-container {
  background-color: white;
  //height: 90vh;
  margin-top: 0.5vw;
  margin-bottom: 1vw;
  width: 98vw;
  border-radius: 0.5em;
  color: #880e4f;
  // height: 95vh;
}

.shadow-right {
  padding-right: 10px;
  // box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.32);
  overflow: auto;
  // margin-top: 35px;
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .projectDetails {
    display: none;
    box-sizing: content-box;
  }
}
