// .project-name-box {
//   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.32);
// }

// svg {
//   margin-left: 300px;
//   cursor: pointer;
//   user-select: none;
// }

// @media screen and (min-width: 375px) and (max-width: 440px) {
//   svg {
//     margin-left: 260px;
//   }
// }

// @media screen and (min-width: 320px) and (max-width: 360px) {
//   svg {
//     margin-left: 200px;
//   }
// }

// @media screen and (min-width: 1000px) and (max-width: 1300px) {
//   svg {
//     margin-left: 180px;
//   }
// }

// @media screen and (min-width: 500px) and (max-width: 640px) {
//   svg {
//     margin-left: 100px;
//   }
// }

// @media screen and (min-width: 660px) and (max-width: 960px) {
//   svg {
//     margin-left: 110px;
//   }
// }



.projectBox {
  color: var(--primaryColor);
  background-color: white;
//  width:300px;
  margin: 20px 30px;
  padding: 20px 30px;
  text-align: center;
  border: 1px solid var(--primaryColor);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

h5{
  font-weight: 900;
  font-size: 25px;
}
h6{
  font-size: 20px;
}
.text{

  flex: 1;
  text-align: center;
}

.playIcon {
  position: absolute;
  right: 15px;
  font-size: 40px;
  opacity: 0;
}

.projectBox:hover {
  .playIcon {
    opacity: 1;
  }
}

.selected {
  background-color: var(--primaryColor);
  color: white;
  .playIcon {
    opacity: 1;
  }
}
