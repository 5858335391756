$color-1 : #880e4f;
$color-2 : #ad1457;

.col1{
  color: $color-1;
}

.col2{
  color: $color-2;
}

.font-weight-bold{
  font-weight: 500!important;
}

.overflow{
  height: inherit;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #b30000;
}

@media (max-width: 770px) {
  ::-webkit-scrollbar-thumb {
    background: white;
  }
}